import React, { Fragment } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Seo from "../components/Seo"
import Layout from "../components/Layout"
import { UseQueryData } from "../hooks/use-query"

const indexPage = () => {
  const data = UseQueryData()
  const filteredPortfolio = data.edges.filter(object => object.node.fields.sourceName === "portfolios")

  return (
    <Fragment>
      <Seo
        title={"Web Development Ninja"}
        description={"Perth based web developer specialising in UX. Creator of Flexi Tennis Leagues AU and overall nice guy."}
      />
      <Layout data={filteredPortfolio} type={"home"}>
        <MainContent />
      </Layout>
    </Fragment>
  )
}

const MainContent = ({ theportfolios }) => (
  <main id="main-grid">
    {theportfolios.map(object => (
      <Link key={object.node.id} className="portfolio" to={object.node.fields.slug}>
        <Img fluid={object.node.frontmatter.featuredImage.childImageSharp.fluid} />
        <div className="title-section">
          <p>{object.node.frontmatter.title}</p>
        </div>
      </Link>
    ))}
  </main>
)

export default indexPage

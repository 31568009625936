import { useStaticQuery, graphql } from "gatsby"

export const UseQueryData = () => {
  const result = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            id
            html
            fields {
              slug
              sourceName
            }
            frontmatter {
              title
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              tags
            }
          }
        }
      }
    }
  `)
  return result.allMarkdownRemark
}
